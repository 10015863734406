import {
  genericFetchInitialState,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import { FETCH_PLAY_REDUCED } from './actions';

export default function playReducedReducer(
  state = genericFetchInitialState,
  action,
) {
  switch (action.type) {
    case FETCH_PLAY_REDUCED:
      return genericFetchReducer(state, action.wrappedAction);

    default:
      return state;
  }
}
