import {
  FETCH_GENERIC_FAILURE,
  FETCH_GENERIC_PAST_DELAY,
  FETCH_GENERIC_START,
  FETCH_GENERIC_SUCCESS,
} from '../actions/genericFetch';

export const genericFetchInitialState = {
  fetching: false,
  pastDelay: false,
  error: undefined,
  retryAction: undefined,
  data: undefined,
};

export const successState = {
  fetching: false,
  pastDelay: false,
  error: undefined,
  retryAction: undefined,
};

export function genericFetchReducer(
  state = genericFetchInitialState,
  action,
  { updateData = (_oldData, newData) => newData } = {},
) {
  switch (action.type) {
    case FETCH_GENERIC_START:
      return {
        ...state,
        fetching: true,
        pastDelay: false,
      };

    case FETCH_GENERIC_PAST_DELAY:
      return state.fetching
        ? {
            ...state,
            pastDelay: true,
            error: undefined,
            retryAction: undefined,
          }
        : state;

    case FETCH_GENERIC_SUCCESS:
      return {
        ...state,
        ...successState,
        data: updateData(state.data, action.data),
      };

    case FETCH_GENERIC_FAILURE:
      return {
        ...state,
        fetching: false,
        pastDelay: false,
        error: action.error,
        retryAction: action.retryAction,
      };

    default:
      return state;
  }
}

/**
 * Like `genericFetch` but expects `.data.results` to be an array. Appends to
 * that array instead of replacing it.
 */
export function genericFetchMoreReducer(
  state = genericFetchInitialState,
  action,
) {
  return genericFetchReducer(state, action, {
    updateData: (oldData, newData) => ({
      ...newData,
      results: [...oldData.results, ...newData.results],
    }),
  });
}

/**
 * Stores the result keyed by Id. Kind of
 * https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
 */
export function genericFetchKeyedReducer(state, base, key, action) {
  if (action.type === FETCH_GENERIC_SUCCESS) {
    /* DELETE */
    if (!action.data) {
      return {
        ...state,
        [base]: {
          ...state[base],
          [key]: genericFetchInitialState,
        },
      };
    }

    return {
      ...state,
      [base]: {
        ...state[base],
        [key]: {
          ...successState,
          data: action.data,
        },
      },
    };
  }

  return {
    ...state,
    [base]: {
      ...state[base],
      [key]: genericFetchReducer(state[base][key], action),
    },
  };
}

/**
 * Same as `genericFetchKeyedReducer` but handles lists of content.
 * Stores a array of id´s
 */
export function genericFetchMultipleKeyedReducer(
  state,
  base,
  key,
  action,
  append = false,
) {
  if (action.type === FETCH_GENERIC_SUCCESS) {
    const newState = {
      ...state,
      [key]: {
        ...successState,
        data: state[key].data && append ? state[key].data : [],
        next: action.data.next,
      },
    };

    action.data.results.map(obj => {
      newState[base][obj.id] = {
        ...successState,
        data: obj,
      };
      newState[key].data.push(obj.id);
      return true;
    });

    return newState;
  }
  return {
    ...state,
    [key]: genericFetchReducer(state[key], action),
  };
}
