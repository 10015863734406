import { useDebouncedValue } from '@trmediaab/zebra-hooks';
import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { WAIT_FOR_ALL_AD_SLOTS } from './constants';

const AdsLoaderContext = createContext({
  addSlotToLoader: () => {},
  removeSlotFromLoader: () => {},
});

export const useAdsLoaderContext = () => {
  const context = useContext(AdsLoaderContext);
  if (context == null) {
    throw new Error(
      'useAdsLoaderContext must be used within AdsLoaderContext.Provider',
    );
  }
  return context;
};

// AdsLoaderProvider manages the lifecycle of adslots created with the
// useDfpSlot() hook. It is responsible for telling useDfpSlots to register
// and unregister with Google Publisher Tag.
export const AdsLoaderProvider = ({ children }) => {
  const [slots, setSlots] = useState(new Map());
  const [debouncedSlots] = useDebouncedValue(slots, WAIT_FOR_ALL_AD_SLOTS);

  // Refreshes slots that are not filled and not removed.
  const refreshAds = useCallback(slots => {
    window.googletag.cmd.push(() => {
      if (
        document.cookie.includes('OptanonAlertBoxClosed') &&
        (window.Optanon?.IsAlertBoxClosed() ?? true)
      ) {
        // Only refresh slots that needs to be refreshed
        const slotsToRefresh = [...slots.values()]
          .filter(slot => slot.gptSlot && !slot.filled && !slot.removed)
          .map(slot => slot.gptSlot);

        if (slotsToRefresh.length === 0) {
          return;
        }

        window.googletag.pubads().refresh(slotsToRefresh);
      }
    });
  }, []);

  // Updates the slots array and refreshes ads.
  const updateAdSlots = useCallback(
    slots => {
      // useDfpSlot guards against calling defineSlot() multiple times.
      slots.forEach(slot => slot.defineSlot());
      // Refresh ads after all slots are defined, guards exists in refreshAds.
      refreshAds(slots);
    },
    [refreshAds],
  );

  // Updates ad slots when the slots array is done changing.
  useEffect(() => {
    if (debouncedSlots.size > 0) {
      updateAdSlots(debouncedSlots);
    }
  }, [debouncedSlots, updateAdSlots]);

  const addSlotToLoader = useCallback(slot => {
    setSlots(map => new Map(map.set(slot.slotId, slot)));
  }, []);

  const removeSlotFromLoader = useCallback(slot => {
    setSlots(map => {
      map.delete(slot.slotId);
      return new Map(map);
    });
  }, []);

  const refreshSlotId = useCallback(
    slotId => {
      window.googletag.cmd.push(() => {
        if (
          document.cookie.includes('OptanonAlertBoxClosed') &&
          (window.Optanon?.IsAlertBoxClosed() ?? true)
        ) {
          const slotToRefresh = slots.get(slotId)?.gptSlot;

          if (slotToRefresh) {
            window.googletag.pubads().refresh([slotToRefresh]);
          }
        }
      });
    },
    [slots],
  );

  const contextValue = useMemo(
    () => ({
      addSlotToLoader,
      removeSlotFromLoader,
      refreshSlotId,
    }),
    [addSlotToLoader, removeSlotFromLoader, refreshSlotId],
  );

  return (
    <AdsLoaderContext.Provider value={contextValue}>
      {children}
    </AdsLoaderContext.Provider>
  );
};

AdsLoaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
