import { createSelector } from 'reselect';

import { locationSelector } from '../../main/selectors';

export const profilesBySlugSelector = state => state.profiles.profilesBySlug;
const liveTipsBySlugSelector = state => state.profiles.liveTipsBySlug;
const historicTipsBySlugSelector = state => state.profiles.historicTipsBySlug;

export const tipsterProfileSelector = createSelector(
  locationSelector,
  profilesBySlugSelector,
  (location, profilesBySlug) => {
    const { slug } = location.payload;
    return (
      profilesBySlug[slug] || {
        fetching: false,
        error: new Error(`Kunde inte hitta innehåll för ${slug}`),
      }
    );
  },
);

export const getBetTipsLiveSelector = createSelector(
  locationSelector,
  liveTipsBySlugSelector,
  (location, tipsBySlug) => {
    const { slug } = location.payload;
    return (
      tipsBySlug[slug] || {
        fetching: false,
        error: new Error(`Kunde inte hitta innehåll för ${slug}`),
      }
    );
  },
);

export const getBetTipsHistorySelector = createSelector(
  locationSelector,
  historicTipsBySlugSelector,
  (location, tipsBySlug) => {
    const { slug } = location.payload;
    return (
      tipsBySlug[slug] || {
        fetching: false,
        error: new Error(`Kunde inte hitta innehåll för ${slug}`),
      }
    );
  },
);
