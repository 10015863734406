import { createSelector } from 'reselect';

import { genericFetchInitialState } from '../../main/reducers/genericFetch';
import { locationSelector } from '../../main/selectors';
import { contentBySlugSelector } from '../../main/selectors/models';

export const soccerBaseSelector = state => state.soccer;

export const selectLeagues = createSelector(
  soccerBaseSelector,
  soccer => soccer.leagues,
);

export const selectTeams = createSelector(
  soccerBaseSelector,
  soccer => soccer.teamsByLeagueTag,
);

export const selectTeamsByLeagueSlug = createSelector(
  locationSelector,
  contentBySlugSelector,
  soccerBaseSelector,
  (location, contentBySlug, soccer) => {
    const { slug } = location.payload;
    const leagueContent = contentBySlug[slug];
    const leagueSlug = leagueContent.data?.tags[0].slug;
    return soccer.teamsByLeagueTag[leagueSlug] || genericFetchInitialState;
  },
);
