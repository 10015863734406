import PropTypes from 'prop-types';
import { createSelector } from 'reselect';

import { contentBySlugSelector, roundsSelector } from './models';

export const locationSelector = state => state.location;

export const locationPropType = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  payload: PropTypes.object.isRequired,
  query: PropTypes.object,
  search: PropTypes.string,
  prev: PropTypes.object,
  kind: PropTypes.string,
  history: PropTypes.object,
  routesMap: PropTypes.object.isRequired,
  hasSSR: PropTypes.bool,
});

export const roundByLocationSelector = createSelector(
  [locationSelector, roundsSelector],
  (location, rounds) => {
    const { gameType, roundId } = location.payload;
    const roundsByType = rounds[gameType];

    if (roundsByType == null) {
      return;
    }

    return roundId == null ||
      (roundsByType.latest.data != null &&
        roundsByType.latest.data.id === roundId)
      ? roundsByType.latest
      : roundsByType[roundId];
  },
);

export const createGenericKeyedSelector = (baseSelector, baseTable, table) =>
  createSelector(baseSelector, base => {
    if (base[table].data) {
      return {
        ...base[table],
        data: base[table].data.map(id => base[baseTable][id].data),
      };
    }
    return base[table];
  });

export const articleSelector = createSelector(
  locationSelector,
  contentBySlugSelector,
  (location, contentBySlug) => {
    const { slug } = location.payload;
    return (
      contentBySlug[slug] || {
        fetching: false,
        error: new Error(`Kunde inte hitta innehåll för ${slug}`),
      }
    );
  },
);
