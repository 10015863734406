import { useWindowEvents } from '@trmediaab/zebra-hooks';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';

import config from '../../main/config';
import { getWindowSize } from '../../utils/dom';
import { useAdsLoaderContext } from './AdsLoaderContext';
import { STATUS } from './constants';
import styles from './styles.scss';
import useDfpSlot from './useDfpSlot';

const Slot = ({
  name,
  sizes,
  sizeMapping,
  renderOutOfThePage,
  onStatusChange,
  refreshKey,
  ...slotProps
}) => {
  const node = useRef(null);
  const windowSize = useRef(getWindowSize());
  const lastRefreshKey = useRef(refreshKey);
  const { refreshSlotId } = useAdsLoaderContext();

  const scaleFn = useCallback(() => {
    if (node.current == null || sizeMapping == null) {
      return;
    }
    const adWidth = node.current.clientWidth;
    const adHeight = node.current.clientHeight;
    const availableWidth = node.current.parentNode.clientWidth;
    const scale = adWidth > availableWidth ? availableWidth / adWidth : 1;
    node.current.style.transform = `scale(${scale})`;
    node.current.style.marginBottom = `${adHeight * (1 - scale) * -1}px`;
  }, [sizeMapping]);

  const onResize = useCallback(() => {
    if (
      sizeMapping != null &&
      windowSize.current?.width !== getWindowSize().width
    ) {
      windowSize.current = getWindowSize();
      scaleFn();
    }
  }, [sizeMapping, scaleFn]);

  const onRender = useCallback(
    event => {
      const status = event.isEmpty ? STATUS.EMPTY : STATUS.FILLED;
      if (status === STATUS.FILLED) {
        scaleFn();
      }

      onStatusChange(status);
    },
    [onStatusChange, scaleFn],
  );

  useWindowEvents('resize', onResize);

  const slotId = useDfpSlot({
    path: `${config.DFP_NETWORK_ID}/${name}`,
    sizes,
    sizeMapping,
    onSlotRender: onRender,
    renderOutOfThePage,
  });

  useEffect(() => {
    if (lastRefreshKey.current !== refreshKey) {
      refreshSlotId(slotId);
      lastRefreshKey.current = refreshKey;
    }
  }, [slotId, refreshKey, refreshSlotId]);

  return (
    <div ref={node} className={styles['ad--panorama--slot']}>
      {slotId && <div id={slotId} {...slotProps} />}
    </div>
  );
};

Slot.propTypes = {
  name: PropTypes.string.isRequired,
  sizes: PropTypes.array,
  sizeMapping: PropTypes.array,
  renderOutOfThePage: PropTypes.bool,
  onStatusChange: PropTypes.func.isRequired,
  refreshKey: PropTypes.string,
};

export default Slot;
