export const FETCH_BETTING_SCHOOL = 'school/FETCH_BETTING_SCHOOL';
export const FETCH_MORE_SCHOOL_POSTS = 'school/FETCH_MORE_SCHOOL_POSTS';
export const START_FETCH_MORE_SCHOOL_POSTS =
  'school/START_FETCH_MORE_SCHOOL_POSTS';
export const FETCH_SCHOOL_POSTS_BY_AUTHOR =
  'school/FETCH_SCHOOL_POSTS_BY_AUTHOR';
export const FETCH_SCHOOL_TIPSTERS = 'school/FETCH_SCHOOL_TIPSTERS';

export const fetchBettingSchool = id => wrappedAction => ({
  type: FETCH_BETTING_SCHOOL,
  wrappedAction,
  id,
});

export const fetchMoreSchoolPosts = id => wrappedAction => ({
  type: FETCH_MORE_SCHOOL_POSTS,
  wrappedAction,
  id,
});

export const startFetchMoreSchoolPosts = id => ({
  type: START_FETCH_MORE_SCHOOL_POSTS,
  id,
});

export const fetchByAuthorId = ({ id }) => ({
  type: FETCH_SCHOOL_POSTS_BY_AUTHOR,
  payload: { id },
});

export const fetchTipstersAction = wrappedAction => ({
  type: FETCH_SCHOOL_TIPSTERS,
  wrappedAction,
});
