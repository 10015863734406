export const FETCH_GENERIC_START = 'main/FETCH_GENERIC_START';
export const FETCH_GENERIC_PAST_DELAY = 'main/FETCH_GENERIC_PAST_DELAY';
export const FETCH_GENERIC_SUCCESS = 'main/FETCH_GENERIC_SUCCESS';
export const FETCH_GENERIC_FAILURE = 'main/FETCH_GENERIC_FAILURE';

export const fetchGenericStart = () => ({
  type: FETCH_GENERIC_START,
});

export const fetchGenericPastDelay = () => ({
  type: FETCH_GENERIC_PAST_DELAY,
});

export const fetchGenericSuccess = data => ({
  type: FETCH_GENERIC_SUCCESS,
  data,
});

export const fetchGenericFailure = (error, retryAction) => ({
  type: FETCH_GENERIC_FAILURE,
  error,
  retryAction,
});
