export const FETCH_POPULAR_TEAMS = 'statistics/FETCH_POPULAR_TEAMS';
export const FETCH_POPULAR_TOURNAMENTS = 'statistics/FETCH_POPULAR_TOURNAMENTS';
export const FETCH_TEAM1_STATISTICS = 'statistics/FETCH_TEAM1_STATISTICS';
export const FETCH_TEAM2_STATISTICS = 'statistics/FETCH_TEAM2_STATISTICS';
export const FETCH_TEAMS_LIST = 'statistics/FETCH_TEAMS_LIST';
export const FETCH_MORE_TEAMS_LIST = 'statistics/FETCH_MORE_TEAMS_LIST';
export const START_FETCH_MORE_TEAMS_LIST =
  'statistics/START_FETCH_MORE_TEAMS_LIST';
export const FILTER_CHANGE = 'statistics/FILTER_CHANGE';
export const TOGGLE_OVER_UNDER = 'statistics/TOGGLE_OVER_UNDER';
export const QUERY_CHANGE = 'statistics/QUERY_CHANGE';

export const fetchPopularTeams = wrappedAction => ({
  type: FETCH_POPULAR_TEAMS,
  wrappedAction,
});

export const fetchPopularTournaments = wrappedAction => ({
  type: FETCH_POPULAR_TOURNAMENTS,
  wrappedAction,
});

export const fetchTeam1Statistics = wrappedAction => ({
  type: FETCH_TEAM1_STATISTICS,
  wrappedAction,
});

export const fetchTeam2Statistics = wrappedAction => ({
  type: FETCH_TEAM2_STATISTICS,
  wrappedAction,
});

export const fetchTeamsList = wrappedAction => ({
  type: FETCH_TEAMS_LIST,
  wrappedAction,
});

export const fetchMoreTeamsList = wrappedAction => ({
  type: FETCH_MORE_TEAMS_LIST,
  wrappedAction,
});

export const startFetchMoreTeamsList = () => ({
  type: START_FETCH_MORE_TEAMS_LIST,
});

export const filterChange = ({ key, value }) => ({
  type: FILTER_CHANGE,
  key,
  value,
});

export const toggleOverUnder = ({ id }) => ({
  type: TOGGLE_OVER_UNDER,
  id,
});

export const queryChange = ({ query }) => ({
  type: QUERY_CHANGE,
  query,
});
