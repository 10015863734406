import { createSelector } from 'reselect';

import { gameSelector } from '../selectors';

export const tableSelector = createSelector(gameSelector, game => game.table);

export const tableFiltersSelector = createSelector(
  tableSelector,
  table => table.filters,
);

export const standingsSelector = createSelector(
  tableSelector,
  table => table.standings,
);
