import PropTypes from 'prop-types';

import FootballIcon from '../../icons/football.svg';
import { classList } from '../../utils';
import Icon from '../Icon';
import styles from './styles.scss';

Spinner.propTypes = {
  delay: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.number.isRequired,
  ]),
  className: PropTypes.string,
};

/**
 * Show it while loading. Optionally, fade it in after a small delay.
 */
export default function Spinner({
  delay = false,
  className = '',
  ...restProps
}) {
  return (
    <span
      className={classList({
        [styles.root]: true,
        [styles.delay]: delay !== false,
        [className]: true,
      })}
      style={{
        animationDelay: typeof delay === 'number' ? `${delay}ms` : undefined,
      }}
      {...restProps}
    >
      <Icon icon={FootballIcon} className={styles.root} />
    </span>
  );
}
