import { getImageImpressionUrl } from '../../utils';

// This component is soley responsible for helping trade doubler count impressions
const SvSpelPixel = () => (
  <img
    src={getImageImpressionUrl()}
    alt="svSpelPixel"
    style={{ display: 'none' }}
  />
);

export default SvSpelPixel;
