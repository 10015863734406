import { useWindowEvents } from '@trmediaab/zebra-hooks';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import config from '../../main/config';
import { classList } from '../../utils';
import { getWindowSize } from '../../utils/dom';
import { ADS_PREFIX, PLACEMENTS, STATUS, UNIT_CONFIGS } from './constants';
import Slot from './Slot';
import styles from './styles.scss';

const UNITS = Object.keys(PLACEMENTS).reduce((acc, placement) => {
  let sizes, sizeMapping;

  if (placement !== 'Takeover') {
    const sizeKey = placement.toLowerCase().match(/^\S+/)[0];
    const config = UNIT_CONFIGS[sizeKey];
    let hasSizeMapping;

    [sizes, hasSizeMapping] = config.reduce(
      (acc2, { sizes, viewport }) => {
        acc2[0].push(...sizes);
        if (Array.isArray(viewport)) {
          acc2[1] = true;
        }
        return acc2;
      },
      [[], false],
    );

    if (hasSizeMapping) {
      sizeMapping = config;
    }
  }

  const unit = {
    name: `${ADS_PREFIX}_${PLACEMENTS[placement].replace(' ', '_')}`,
    sizes,
    sizeMapping,
  };

  return {
    ...acc,
    [placement]: unit,
  };
}, {});

const Ad = ({
  placement,
  showLabel = true,
  wrap = content => content,
  deviceFilter = 'none',
  refreshKey = null,
}) => {
  const windowSize = useRef(getWindowSize());
  const [status, setStatus] = useState(
    config.ADS_ENABLED ? STATUS.INITIAL : STATUS.FILLED,
  );
  const [visible, setVisible] = useState(false);

  const checkVisibility = useCallback(() => {
    if (
      deviceFilter === 'mobileOnly' &&
      windowSize.current?.breakpoints?.desktop
    ) {
      return setVisible(false);
    }

    // Skip desktop only on smaller screen
    if (
      deviceFilter === 'desktopOnly' &&
      !windowSize.current?.breakpoints?.desktop
    ) {
      return setVisible(false);
    }

    if (windowSize.current?.width !== getWindowSize().width) {
      windowSize.current = getWindowSize();
    }

    setVisible(true);
  }, [deviceFilter]);

  useEffect(() => {
    checkVisibility();
  }, [checkVisibility]);

  useWindowEvents('resize', checkVisibility);

  if (!visible) {
    return null;
  }

  const { name, sizes, sizeMapping } = UNITS[placement];

  let slot;

  if (config.ADS_ENABLED) {
    slot = (
      <Slot
        name={name}
        sizes={sizes}
        sizeMapping={sizeMapping}
        onStatusChange={setStatus}
        renderOutOfThePage={placement === 'Takeover'}
        refreshKey={refreshKey}
      />
    );
  } else {
    // Render a placeholder when ads are disabled, except for the
    // takeover where a placeholder doesn't make sense
    if (placement === 'Takeover') {
      slot = null;
    } else {
      slot = (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#eee',
            maxWidth: '100%',
            opacity: 0.4,
            width: `${sizes[sizes.length - 1][0]}px`,
            height: `${sizes[sizes.length - 1][1]}px`,
          }}
        >
          <span style={{ color: '#bbb', fontWeight: 700, textAlign: 'center' }}>
            {placement}
            <br />
            {sizes[sizes.length - 1].join('x')}
          </span>
        </div>
      );
    }
  }

  const display = status === STATUS.EMPTY ? 'ad--none' : 'ad--flex';
  const visibility =
    status === STATUS.INITIAL
      ? 'ad--hidden'
      : status === STATUS.FILLED
      ? 'ad--visible'
      : 'ad--initial';

  return wrap(
    <div
      style={{ justifyContent: 'center' }}
      className={classList({
        [styles[display]]: true,
        [styles[visibility]]: true,
        [styles['ad--skyscraper']]: placement.includes('skyscraper'),
      })}
    >
      {showLabel ? (
        <div className={styles['ad__wrapper']}>
          <span className={styles['ad__label']}>Annons</span>
          {slot}
        </div>
      ) : (
        slot
      )}
    </div>,
    status === STATUS.FILLED,
  );
};

Ad.propTypes = {
  placement: PropTypes.oneOf(Object.keys(PLACEMENTS)).isRequired,
  showLabel: PropTypes.bool,
  wrap: PropTypes.func,
  deviceFilter: PropTypes.oneOf(['none', 'mobileOnly', 'desktopOnly']),
  refreshKey: PropTypes.string,
};

export default memo(Ad);
