/* global APP_CONFIG */

// `APP_CONFIG` is replaced by an object by webpack.

const isEmbedPage = window.location.pathname.startsWith('/embed');

const config = {
  ...APP_CONFIG,
  GTM_ENABLED: APP_CONFIG.GTM_ENABLED && !isEmbedPage,
  APP_ELEMENT_ID: 'app',
  LOADABLE_TIMEOUT: 10e3, // ms
  OVERODDS_EXPERTER_SLUG: 'overodds-experter',
};

// For debugging.
window.__config = { ...config };

export default config;
