import { createSelector } from 'reselect';

import { ANALYSIS_POST_EDIT } from '../../main/actions/router';
import { genericFetchInitialState } from '../../main/reducers/genericFetch';
import {
  createGenericKeyedSelector,
  locationSelector,
} from '../../main/selectors';

export const analysesSelector = state => state.analyses;

export const contentListSelector = createSelector(
  analysesSelector,
  analyses => analyses.contentList,
);

export const availableFiltersSelector = createSelector(
  analysesSelector,
  analyses => analyses.availableFilters,
);

export const selectedFilterKeySelector = createSelector(
  analysesSelector,
  analyses => analyses.selectedFilterKey,
);

export const latestAnalysesByFilterKeySelector = createSelector(
  analysesSelector,
  analyses => analyses.analysesByFilterKey,
);

export const authenticatedTipsterSelector = createSelector(
  analysesSelector,
  data =>
    (data && data.authenticatedTipster && data.authenticatedTipster.data) ||
    undefined,
);

export const eventGroups = createSelector(
  analysesSelector,
  analyses => analyses.eventGroups,
);

export const eventListSelector = createSelector(
  analysesSelector,
  analyses => analyses.eventList,
);

export const eventGroupsFilter = createSelector(
  analysesSelector,
  analyses => analyses.eventGroupsFilter,
);

export const privateAnalyses = createSelector(
  analysesSelector,
  analyses => analyses.privateAnalyses,
);

export const privateAnalysesSelector = createSelector(
  locationSelector,
  privateAnalyses,
  (location, data) => {
    const { analysisId } = location.payload;
    return data[analysisId] || genericFetchInitialState;
  },
);

export const publicAnalyses = createSelector(
  analysesSelector,
  analyses => analyses.publicAnalyses,
);

export const publicAnalysesSelector = createSelector(
  locationSelector,
  analysesSelector,
  (location, data) => {
    const { analysisId } = location.payload;
    return data.publicAnalyses[analysisId] || genericFetchInitialState;
  },
);

export const formState = createSelector(
  locationSelector,
  analysesSelector,
  (location, data) => {
    if (location.type === ANALYSIS_POST_EDIT) {
      const { analysisId } = location.payload;
      return data.privateAnalyses[analysisId] || genericFetchInitialState;
    }
    return data.privateAnalyses.new || genericFetchInitialState;
  },
);

export const eventById = createSelector(
  analysesSelector,
  analyses => analyses.eventById,
);

export const eventByIdSelector = createSelector(
  locationSelector,
  eventById,
  privateAnalysesSelector,
  (location, data, analysis) => {
    if (location.type === ANALYSIS_POST_EDIT && analysis.data) {
      const {
        event: { id },
      } = analysis.data;

      return data[id] || genericFetchInitialState;
    }
    const { eventId } = location.payload;
    return data[eventId] || genericFetchInitialState;
  },
);

export const selectSports = createSelector(eventGroups, groups => {
  const sports = [{ value: '', title: 'Välj' }];
  if (groups) {
    groups.data.map(group => {
      if (!sports.some(obj => obj.value === group.sport.key)) {
        sports.push({
          value: group.sport.key,
          title: group.sport.label,
        });
      }
      return true;
    });
  }
  return sports;
});

export const selectRegions = createSelector(
  eventGroups,
  eventGroupsFilter,
  (groups, filter) => {
    const regions = [{ value: '', title: 'Välj' }];
    if (groups && filter.sport) {
      groups.data
        .filter(group => group.sport.key === filter.sport)
        .map(group => {
          if (!regions.some(obj => obj.value === group.parent.label)) {
            regions.push({
              value: group.parent.label,
              title: group.parent.label,
            });
          }
          return true;
        });
    }

    return regions;
  },
);

export const selectGroups = createSelector(
  eventGroups,
  eventGroupsFilter,
  (groups, filter) => {
    const eventgroups = [{ value: '', title: 'Välj' }];
    if (groups && filter.sport && filter.region) {
      groups.data
        .filter(
          group =>
            group.sport.key === filter.sport &&
            group.parent.label === filter.region,
        )
        .map(group => {
          if (!eventgroups.some(obj => obj.value === group.id)) {
            eventgroups.push({
              value: group.id.toString(),
              title: group.name,
            });
          }
          return true;
        });
    }

    return eventgroups;
  },
);

export const analysisFormData = createSelector(
  analysesSelector,
  data => data.formData,
);

export const uncorrectedSelector = createGenericKeyedSelector(
  analysesSelector,
  'privateAnalyses',
  'adminUncorrected',
);

export const upcomingSelector = createGenericKeyedSelector(
  analysesSelector,
  'privateAnalyses',
  'adminUpcoming',
);
