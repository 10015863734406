export const FETCH_LEAGUE_POSTS = 'soccer/FETCH_LEAGUE_POSTS';
export const FETCH_LEAGUE_POST = 'soccer/FETCH_LEAGUE_POST';
export const START_FETCH_TEAMS = 'soccer/START_FETCH_TEAMS';
export const FETCH_TEAM_POSTS = 'soccer/FETCH_TEAM_POSTS';
export const FETCH_TEAM_POST = 'soccer/FETCH_TEAM_POST';

export const fetchLeaguePosts = wrappedAction => ({
  wrappedAction,
  type: FETCH_LEAGUE_POSTS,
});

export const fetchTeamPosts = tag => wrappedAction => ({
  wrappedAction,
  type: FETCH_TEAM_POSTS,
  tag,
});
