export const FETCH_BLOG_POSTS = 'blog/FETCH_BLOG_POSTS';
export const FETCH_BIG9_BLOG_POSTS = 'big9/FETCH_BIG9_BLOG_POSTS';
export const FETCH_MORE_BLOG_POSTS = 'blog/FETCH_MORE_BLOG_POSTS';
export const START_FETCH_MORE_BLOG_POSTS = 'blog/START_FETCH_MORE_BLOG_POSTS';
export const FETCH_AUTHORS = 'blog/FETCH_AUTHORS';
export const FETCH_BLOG_POSTS_BY_AUTHOR = 'blog/FETCH_BLOG_POSTS_BY_AUTHOR';
export const FETCH_BLOG_POSTS_BY_AUTHOR_ID =
  'blog/FETCH_BLOG_POSTS_BY_AUTHOR_ID';

export const fetchBlogPosts = wrappedAction => ({
  type: FETCH_BLOG_POSTS,
  wrappedAction,
});

export const fetchBig9BlogPosts = wrappedAction => ({
  type: FETCH_BIG9_BLOG_POSTS,
  wrappedAction,
});

export const fetchAuthors = wrappedAction => ({
  type: FETCH_AUTHORS,
  wrappedAction,
});

export const fetchMoreBlogPosts = id => wrappedAction => ({
  type: FETCH_MORE_BLOG_POSTS,
  wrappedAction,
  id,
});

export const startFetchMoreBlogPosts = id => ({
  type: START_FETCH_MORE_BLOG_POSTS,
  id,
});

export const fetchByAuthorId = ({ authorId }) => ({
  type: FETCH_BLOG_POSTS_BY_AUTHOR,
  authorId,
});

export const fetchBlogPostsByAuthorId = authorId => wrappedAction => ({
  type: FETCH_BLOG_POSTS_BY_AUTHOR_ID,
  wrappedAction,
  id: authorId ?? 'all',
});
