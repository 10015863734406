import PropTypes from 'prop-types';
import { Component } from 'react';

import { noop } from '../../utils';

/**
 * Listen for `eventName` globally (or on any `target`).
 *
 * Listen for clicks on a div:
 *
 *     <div onClick={condition ? onClick : null}>
 *       ...
 *     </div>;
 *
 * Listen for clicks globally:
 *
 *     <div>
 *       {condition && <EventListener eventName="click" listener={onClick} />}
 *       ...
 *     </div>;
 *
 * In both cases, the event listener is only present if `condition` is true and
 * the div exists. No need to manually add and remove listeners at appropriate
 * times.
 */
export default class EventListener extends Component {
  constructor(props) {
    super(props);

    this.removeListener = noop;
  }

  componentDidMount() {
    const {
      eventName,
      listener,
      target = window,
      options = {
        capture: true,
        passive: false,
      },
    } = this.props;

    target.addEventListener(eventName, listener, options);

    this.removeListener = () => {
      target.removeEventListener(eventName, listener, options);
    };
  }

  componentWillUnmount() {
    this.removeListener();
  }

  render() {
    return null;
  }
}

EventListener.propTypes = {
  eventName: PropTypes.string.isRequired,
  listener: PropTypes.func.isRequired,
  // Internet Explorer doesn’t support `EventTarget`.
  target:
    window.EventTarget == null
      ? PropTypes.any
      : PropTypes.instanceOf(window.EventTarget),
  options: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.shape({
      capture: PropTypes.bool,
      passive: PropTypes.bool,
    }).isRequired,
  ]),
};
