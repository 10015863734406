export const slugs = [
  'daniel-olenklint',
  'christoffer-hansson',
  'herman-stalheden',
];

/* Need to know the author_id to fetch all posts by tipster
 api/v1/content/latest/?type=betting_school&limit=10&author_id=11
 This is not the same as tipster id */
export const mapSlugToId = {
  'daniel-olenklint': 5,
  'christoffer-hansson': 23,
  'herman-stalheden': 8,
};
