import {
  genericFetchInitialState,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import { FETCH_TIPSTER_FORM } from './actions';

export default function tipsterFormReducer(
  state = genericFetchInitialState,
  action,
) {
  switch (action.type) {
    case FETCH_TIPSTER_FORM:
      return genericFetchReducer(state, action.wrappedAction);

    default:
      return state;
  }
}
