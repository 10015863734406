import { createSelector } from 'reselect';

export const playReducedSelector = state => state.playReduced;

export const selectPlayReducedItems = createSelector(
  playReducedSelector,
  state => state.data,
);

export const selectPlayReducedError = createSelector(
  playReducedSelector,
  state => state.error,
);

export const selectPlayReducedLoading = createSelector(
  playReducedSelector,
  state => state.fetching,
);
